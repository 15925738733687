import { getStorage, ref, uploadString, getDownloadURL, deleteObject } from "firebase/storage";

// *********************************************************
// Upload Image functions
// *********************************************************

// Uploads image to storage
const uploadLogImage = async (imageDataUrl, imageSize, currentBikeId, logId) => {
  const storage = getStorage();
  const dir = imageSize === "small" ? "smallImages" : "largeImages";
  const storageRef = ref(storage, `images/${currentBikeId}/${logId}/${dir}/${Date.now()}.png`);
  try {
    await uploadString(storageRef, imageDataUrl, 'data_url');
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading image", error);
    return null;
  }
};

// Uploads dashboard bike image to storage
const uploadBikeImage = async (imageDataUrl, currentBikeId) => {
  const storage = getStorage();
  const storageRef = ref(storage, `images/${currentBikeId}/dashboard/bike-img.png`);
  try {
    await uploadString(storageRef, imageDataUrl, 'data_url');
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading image", error);
    return null;
  }
};

// *********************************************************
// Delete Image functions
// *********************************************************

const deleteImageFromStorage = async (imageObj) => {
  const storage = getStorage();
  const imageURLArr = [imageObj.smallImageURL, imageObj.largeImageURL];

  // Collect promises in an array
  const deletePromises = imageURLArr.map(async (imageURL) => {
    const imageRef = ref(storage, imageURL);
    try {
      await deleteObject(imageRef); // Wait for each image to be deleted
      console.log(`Deleted image: ${imageURL}`);
    } catch (error) {
      console.error(`Error deleting image: ${imageURL}`, error);
    }
  });

  // Wait for all deletions to complete
  return Promise.all(deletePromises);
};

const deleteAllCurrentLogImagesFromStorage = async (logObj) => {
  const logImageDeletions = logObj.photos.map(photo => 
    deleteImageFromStorage(photo)
  );

  // Wait for all log images to be deleted
  return Promise.all(logImageDeletions);
};

const deleteAllCurrentBikeImagesFromStorage = async (bikeObj) => {
  
  // Add the dashboard image deletion to the list of promises
  const dashboardImageDeletion = deleteBikeDashboardImage(bikeObj.dashboard.bikeImagePath);

  // Collect promises for log image deletions
  const logImageDeletions = bikeObj.logs.map(log => 
    deleteAllCurrentLogImagesFromStorage(log)
  );

  // Wait for all deletions (logs and dashboard) to complete
  return Promise.all([...logImageDeletions, dashboardImageDeletion]);
};

const deleteAllUsersImagesFromStorage = async (userObj) => {
  const bikeImageDeletions = userObj.bikes.map(bike => 
    deleteAllCurrentBikeImagesFromStorage(bike)
  );

  // Wait for all bike image deletions to complete
  return Promise.all(bikeImageDeletions);
};

const deleteBikeDashboardImage = async (imageURL) => {
  
  // Check if the imageURL is not an empty string
  if (!imageURL) {
    
    // Return a resolved promise if the URL is empty
    return Promise.resolve();
  }
  
  const storage = getStorage();
  const dashboardImageRef = ref(storage, imageURL);

  // Return the promise from deleteObject
  return deleteObject(dashboardImageRef)
    .then(() => {
      console.log(`Deleted image: ${imageURL}`);
    })
    .catch((error) => {
      console.error(`Error deleting image: ${imageURL}`, error);
    });
};

export { 
  uploadLogImage, 
  uploadBikeImage, 
  deleteImageFromStorage,
  deleteAllCurrentBikeImagesFromStorage,
  deleteAllCurrentLogImagesFromStorage,
  deleteAllUsersImagesFromStorage
};
