// Firebae Config
const firebaseConfig = {
  apiKey: "AIzaSyASSi3TNx3PHRI-uSV7pgU_FS57GxjAisg",
  authDomain: "motodiary-361a3.firebaseapp.com",
  projectId: "motodiary-361a3",
  storageBucket: "motodiary-361a3.appspot.com",
  messagingSenderId: "108013738527",
  appId: "1:108013738527:web:e68c28382d23776452932a"
};

export default firebaseConfig;