import React, { useContext } from "react";
import InfoToolTip from '../Modals/InfoToolTip';
import { WindowContext } from '../../context/WindowContext.jsx'

export default function Main({children}) {
  
  const { headerHeight } = useContext(WindowContext)

  return (
        <div className='main-wr'  style={{ marginTop: `${headerHeight - 1}px` }}>
          {children}
          <InfoToolTip />        
        </div>
    )
}