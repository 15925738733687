import { React, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from '../../assets/logo-pack/logo-dark-small.svg'
import MeImg from '../../assets/donate-me.png';
import AppleStoreImg from '../../assets/icon-apple-store.png';
import PlayStoreImg from '../../assets/icon-play-store.png';
import BuyMeABeerBtn from '../../assets/buy-me-a-beer-btn.png';
import { WindowContext } from '../../context/WindowContext';
import {getTodayDate} from '../../utils'

export default function DonatePage() {
    const { setCurrentPage } = useContext(WindowContext);
   
    useEffect(() => {
        setCurrentPage("donate")
    }, [])

    return (
        <div className="about-center-div">
            <div className="about-wr">
                <h1 className="heading-xxl">Help us grow by donating</h1>
                <p className="text-center">
                    At MotoDiary, we're committed to providing a reliable and user-friendly solution for tracking your motorcycle maintenance. Hosting, maintaining, and continually improving the platform comes with ongoing costs. If you find value in MotoDiary, we would greatly appreciate any donations to help cover these expenses.
                </p>
                <img src={MeImg} alt="Picture of me" className="donate-me-img" />
                <Link to='https://buymeacoffee.com/motodiary'>
                    <img src={BuyMeABeerBtn} alt="Buy me a beer button" className="buy-me-a-beer-btn" />
                </Link>
                <h1 className="heading-xxl big-title">About Me</h1>
                <p className="text-center">“Hey, I'm Matt. 28. —web developer by trade, adventure biker at heart. I’m all about hitting the trails and wrenching on my bikes. The paperwork? Not so much. That’s why I built Motodiary, to ditch the boring stuff and help fellow riders keep track of their bike’s life, hassle-free. Right now, I’m on a mission to conquer all 10,000 km of the UK TET, one muddy trail at a time. If you've donated, a huge thanks! Every bit goes into making Motodiary better, and I’ve got some exciting features in the works—watch this space!”</p>
                <h1 className="heading-xxl">Future Plans for MotoDiary</h1>
                <h2 className="sub-heading-orange text-center">Mobile app available in IOS & Google Play store</h2>
                <div className="dash-app-icon-wr">
                    <img className="dash-app-icon" src={PlayStoreImg} alt="playstore icon" />
                    <img className="dash-app-icon" src={AppleStoreImg} alt="playstore icon" />
                </div>
                <p className="text-center">We're excited to announce that we're working on bringing MotoDiary to your device as a fully native app, soon available for download on both the Google Play Store and the Apple App Store.</p>
                <h2 className="sub-heading-orange text-center">Premium Motorcycle Data</h2>
                <p className="text-center">We're committed to providing the most accurate and complete data available. By subscribing to a high-quality paid motorcycle database, we'll offer reliable, in-depth information to enhance your experience and decision-making.</p>
                <h2 className="sub-heading-orange text-center">Bike Comparison Tool</h2>
                <p className="text-center">Struggling to choose between two bikes? Our upcoming comparison feature will allow you to easily view side-by-side specs, helping you make informed decisions based on detailed, accurate data.</p>
                <h2 className="sub-heading-orange text-center">Torque Specifications Manager</h2>
                <p className="text-center">Tired of constantly referring to your owner's manual for  forgotten torque settings? Our intuitive Torque Specifications page will allow you to input and save all your required values in one place, offering a simple and visual way to keep your specs at hand.</p>
                <h2 className="sub-heading-orange text-center">Suspension Sag Calculator</h2>
                <p className="text-center">Setting up your suspension sag doesn’t have to be complicated. Our future sag calculator will streamline the process, doing the heavy lifting for you to ensure your bike is set up perfectly in just a few simple steps.</p>
                <h2 className="sub-heading-orange text-center">...and more!</h2>
                <div className="footer-logo">
                    <img src={Logo} alt="logo" className="logo" />
                    <p className="copyright-text">© {getTodayDate().slice(0,4)} MotoDiary. All rights reserved</p>
                </div>
            </div>
        </div>
    );
}
