import React, { useState } from "react";
import { auth } from "../../firebase";
import { toast } from "react-toastify";
import GoogleSignIn from "../Auth/GoogleSignIn";
import { signInWithEmailAndPassword } from "firebase/auth";
import Logo from '../../assets/logo-pack/logo-dark-small.svg'
import LoadingSpinner from '../Modules/LoadingSpinner'
import FirebaseAuthImg from '../../assets/firebase-auth-img.svg'

function Login() {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    
    e.preventDefault();
    setLoading(true)
    
    try {
      // Log user into Firebase Auth
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Check if user's email is verified
      if (user.emailVerified) {        
        // Direct user to /profile
        window.location.href = "/profile/logbook";
        toast.success("User logged in successfully", {
          position: "top-center",
        });
      } else {
        throw new Error("Please verify your email before logging in.");
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
      });
    }
    finally {
      setLoading(false)
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <div className="modal-header">
          <img src={Logo} alt="logo" className="auth-logo"/>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-email-wr">
            <input
              type="email"
              className="modal-input"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="username"
              id="login-username"
              />
          </div>

          <div className="auth-pw-wr">
            <input
              id="login-password"
              type="password"
              className="modal-input"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
          </div>

          <div className="auth-footer">
            {!loading ? 
            <button type="submit" className="primary-btn">
              Login
            </button>
            :
            <div style={{
              width: '100%',
              display: 'flex'
            }}>
              <LoadingSpinner />
            </div>
            }
            <GoogleSignIn />
            <div className="modal-links-wr">
                <a className="primary-link body-l" href="/password-reset">Forgotten password?</a>
                <a className="secondary-link body-l" href="/register">Register Here</a>
            </div>
          </div>
          <div className="firebase-auth-img-wr">
            <img className="firebase-auth-img" src={FirebaseAuthImg} alt="firebase auth image" />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
