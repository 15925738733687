import React, { useContext, useState, useEffect } from "react";
import CrossIcon from "../../assets/icon-cross-white.svg";
import DeleteImageIcon from "../../assets/icon-delete-img-cross.svg"
import AddPhotoIcon from "../../assets/icon-add-photo.svg";
import { deleteImageFromStorage } from '../Storage'
import { WindowContext } from "../../context/WindowContext";
import { UserContext } from "../../context/UserContext";
import { v4 as uuidv4 } from 'uuid';
import DeleteConfrimImgModal from '../Modals/DeleteConfirmImgModal'
import { toast } from "react-toastify";
import { verifyModalPhotos } from '../../utils'

export default function EditLog() {
    
    const { modalState, toggleModalState, currentLog, updateCurrentLog, updateCurrentLogToCurrentBike, modalPhotos, setModalPhotos, setLoading} = useContext(WindowContext);
    const { updateCurrentBike } = useContext(UserContext);
    const isEditLogVisible = modalState.editLogModal;
    const [confirmedDelete, setConfirmedDelete] = useState(false)
    const [deleteImgObj, setDeleteImgObj] = useState("")
    const [date, setDate] = useState("");
    const [mileage, setMileage] = useState("");
    const [cost, setCost] = useState("");
    const [completedByShop, setCompletedByShop] = useState("");
    const [tasks, setTasks] = useState([]);
    const [taskInput, setTaskInput] = useState("");
    const [notes, setNotes] = useState("");
    const [errMsg, setErrMsg] = useState("");
    
    // Render currentLog details into modal 
    useEffect(() => {
        if (currentLog) {
            setDate(currentLog.date || "");
            setMileage(currentLog.mileage || "");
            setCost(currentLog.cost || "");
            setCompletedByShop(currentLog.completedByShop || "No");
            setTasks(currentLog.tasks || []);
            setNotes(currentLog.notes || "");
            setModalPhotos(currentLog.photos || [])
        }
    }, [currentLog]);

    // Checks for valid input then saves ammended changed to currentLog
    const handleSubmitLog = async (e) => {  
        e.preventDefault();
        setLoading(true)
        if (currentLog) {
            // Check for valid input
            if (!isValidDate(date)) {
                toast.error("Sorry! Date cannot be in the future.", {
                    position: "top-center",
                });
                setLoading(false)
            } else if (mileage === "") {
                toast.error("Sorry! Mileage cannot be empty.", {
                    position: "top-center",
                });
                setLoading(false)
            } else if (tasks.length === 0) {
                toast.error("Sorry! You need to add atleast 1 task.", {
                    position: "top-center",
              });
                setLoading(false)
            } else {

                // Update CurrentLog
                setErrMsg("");
                const updatedPhotos = await verifyModalPhotos(modalPhotos, setModalPhotos, date).then(({updatedPhotos, allPhotosAreVerified}) => {
                    return ("Updated photos:", {updatedPhotos, allPhotosAreVerified});
                  }).catch((error) => {
                    console.error("Error updating photos:", error);
                  });
    
                // updatedLog Obj
                const updatedLog = {
                    id: currentLog.id,
                    date: date,
                    mileage: mileage,
                    cost: cost,
                    completedByShop: completedByShop,
                    tasks: tasks,
                    notes: notes,
                    photos: updatedPhotos.updatedPhotos,
                    isVerified: updatedPhotos.allPhotosAreVerified
                };
                try {

                    // Update current log in user state
                    const updatedCurrentLog = await updateCurrentLog(updatedLog)

                    // Update currentBike
                    const updatedCurrentBike = await updateCurrentLogToCurrentBike(updatedCurrentLog)

                    // Push current bike to UserState
                    await updateCurrentBike(updatedCurrentBike)

                    // Close modal
                    toggleModalState("editLogModal");
                } catch (error) {
                    console.error("An error occurred while updating the log and bike:", error);
                    setErrMsg("An error occurred while processing your request.");
                }
                finally {
                    setLoading(false)
                }
            }
        }
    };

    const isValidDate = (dateString) => {
        const date = new Date(dateString);
        const today = new Date();

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return false;
        }
        // Check if the date is not later than today
        if (date > today) {
            return false;
        }
        return true;
    };

    // Adds a new task to state and resets task input field
    const handleAddNewTask = () => {
        if (taskInput.trim() !== "") {
            setTasks((prevTasks) => [
                ...prevTasks,
                {
                    id: uuidv4(),
                    taskDesc: taskInput,
                },
            ]);
            setTaskInput("");
        }
    };

    // Removes the task from state when the user clicks on the related 'x'
    const handleRemoveTask = (id) => {
        setTasks((prevTasks) => prevTasks.filter((task) => task.id !== id));
    };

    const handleDeleteLogBtn = async (e) => {
        e.preventDefault();
        // confirm pop up appears
        if (!modalState.deleteConfirmLogModal) {
            toggleModalState("deleteConfirmLogModal")
        }
    } 

    const tasksJSX = tasks.map((task) => {
        return (
            <div className="task-wr" id={task.id} key={task.id}>
                <p className="task-item">{task.taskDesc}</p>
                <img
                    src={CrossIcon}
                    alt="cross icon"
                    className="delete-task-icon"
                    data-id={task.id}
                    onClick={(e) => handleRemoveTask(e.target.dataset.id)}
                />
            </div>
        );
    });

    // Propts user to add a photo
    const openAddPhotoModel = () => {
        toggleModalState("addPhotoModal")
    }

    // Deletes image from log, Firestore and storage
    const handleDeleteImg = (imgObj) => {
        
        // Toggle confirmDeleteImgModal
        toggleModalState('deleteConfirmImgModal')
        
        // Set Img URL to delete
        setDeleteImgObj(imgObj)
        setConfirmedDelete(false)
    }
    
    useEffect(() => {
        if(confirmedDelete) {   
            const deleteImage = async () => {

                // Delete Image from storage
                await deleteImageFromStorage(deleteImgObj)
                
                // Delete Image from current Log
                const filteredArr = await modalPhotos.filter(ImgObj => ImgObj.smallImageURL !== deleteImgObj.smallImageURL)
                setModalPhotos(filteredArr)
                setDeleteImgObj("")
                toggleModalState('deleteConfirmImgModal')
            }
            deleteImage()
        }
    }, [confirmedDelete])

    const photosJSX = modalPhotos.map((photo, index) => {
        return (
            <div className="edit-photo-btn-wr" onClick={() => handleDeleteImg(photo)} key={index}>
                <div className="edit-log-photo-overlay">
                </div>
                    <img src={photo.smallImageURL} className="edit-log-photo opacity" alt="Log Photo" />
                <img src={DeleteImageIcon} className="delete-img-icon" alt="" />
            </div>
        )
    })

    return (
        <>
            <form onSubmit={handleSubmitLog}>
                <div className="modal-overlay" style={isEditLogVisible ? { display: "block" } : { display: "none" }}></div>
                <div className="modal-wr" id="edit-log-modal" style={isEditLogVisible ? { display: "block" } : { display: "none" }}>
                    <div className="modal-header">
                        <h2 className="modal-title heading-xxl">Edit Log</h2>
                        <button className="modal-delete-btn" id={currentLog.id} onClick={(e) => handleDeleteLogBtn(e)}>Delete</button>
                    </div>
                    <div className="date-wr">
                        <div className="modal-date-wr">
                            <h3 className="heading-m">Date</h3>
                            <input
                                autoComplete="off"
                                type="date"
                                name="modal-date-input"
                                id="edit-log-date"
                                onChange={(e) => setDate(e.target.value)}
                                value={date}
                                required
                                disabled
                                style={{color: "var(--grey-3)"}}
                            />
                        </div>
                    </div>
                    <div className="modal-mileage-cost-wr">
                        <div className="modal-mileage-wr">
                            <h3 className="heading-m">Mileage / Hrs</h3>
                            <input
                                autoComplete="off"
                                type="number"
                                className="modal-input"
                                placeholder="0"
                                id="edit-mileage-input"
                                onChange={(e) => setMileage(e.target.value)}
                                value={mileage}
                                required
                            />
                        </div>
                        <div className="modal-cost-wr">
                            <h3 className="heading-m">Cost</h3>
                            <input
                                autoComplete="off"
                                type="number"
                                className="modal-input"
                                placeholder="0"
                                id="edit-cost-input"
                                onChange={(e) => setCost(e.target.value)}
                                value={cost}
                            />
                        </div>
                    </div>
                    <div className="modal-shop-wr">
                        <h3 className="heading-m">Carried Out By Shop?</h3>
                        <select
                            className="dropdown-list"
                            id="modal-shop"
                            name="modal-shop"
                            value={completedByShop}
                            onChange={(e) => setCompletedByShop(e.target.value)}
                        >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>
                    <div className="modal-tasks-wr">
                        <h3 className="heading-m">Tasks</h3>
                        <div className="task-list-wr">
                            {tasksJSX}
                        </div>
                        <div className="task-input-wr">
                            <input
                                autoComplete="off"
                                type="text"
                                id="modal-edit-task-input"
                                className="modal-edit-task-input"
                                placeholder="e.g Changed Oil"
                                onChange={(e) => setTaskInput(e.target.value)}
                                value={taskInput}
                                maxLength={55}
                            />
                        </div>
                        <button type="button" className="secondary-btn" onClick={handleAddNewTask}>Add Task</button>
                    </div>
                    <div className="modal-notes-wr">
                        <h3 className="heading-m">Additional Notes</h3>
                        <textarea
                            name="edit-log-notes"
                            id="edit-log-notes"
                            placeholder="e.g  I used Motorex 10W 40 fully synthetic oil"
                            className="modal-notes"
                            onChange={(e) => setNotes(e.target.value)}
                            value={notes}
                        />
                    </div>
                    <div className="modal-photos-wr">
                        <div className="photos-heading">
                            <h3 className="heading-m">Photos</h3>
                            <p className="photos-count">{modalPhotos.length} of 6</p>
                        </div>
                        <div className="log-photos-grid">
                            {photosJSX && photosJSX}
                            
                            {/* Hide add photo btn if user already has 3 photos */}
                            {modalPhotos.length < 6 && 
                            <button type="button" className="add-photo-btn" onClick={openAddPhotoModel}>
                                <img src={AddPhotoIcon} alt="add photo icon" />
                            </button>}
                        </div>
                    </div>
                    <button type="submit" className="primary-btn" id="edit-log-btn">Amend Log</button>
                    {errMsg && <p className="err-msg body-s">{errMsg}</p>}
                </div>
            </form>
            <DeleteConfrimImgModal 
                setConfirmedDelete={setConfirmedDelete}
                confirmedDelete={confirmedDelete}
                setDeleteImgObj={setDeleteImgObj}
            />
        </>
    );
}
