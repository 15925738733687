import React, { useContext, useEffect, useState } from "react";
import CloseModalBtn from '../../assets/icon-cross-white.svg';
import QRcodeIcon from '../../assets/icon-qr-code.png';
import SwitchCameraIcon from '../../assets/icon-switch-camera.svg';
import { db } from "../../firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { WindowContext } from "../../context/WindowContext";
import { UserContext } from "../../context/UserContext";
import LoadingSpinner from '../Modules/LoadingSpinner';
import QrScanner from 'react-qr-scanner';
import { toast } from "react-toastify";

export default function TransferFromModal() {
    const [isLoading, setIsLoading] = useState(false);
    const { modalState, toggleModalState, setCurrentBike } = useContext(WindowContext);
    const { userDetails, setUserDetails } = useContext(UserContext);
    const isTransferToModalVisible = modalState.transferToModal;
    const [scanResult, setScanResult] = useState(null);
    const [cameraPermissionGranted, setCameraPermissionGranted] = useState(false);
    const [errTimer, setErrTimer] = useState(false);
    const [videoStream, setVideoStream] = useState(null); 
    
    // This tells the browser what camera to use on the device
    const [cameraConstraints, setCameraConstraints] = useState({
        video: { facingMode: { ideal: 'environment' } },
    });
    const [qrScannerKey, setQrScannerKey] = useState(Date.now());  // Key to re-render the scanner    

    // Request camera permission and handle errors
    useEffect(() => {
        const requestCameraPermission = async () => {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                    setCameraPermissionGranted(true);
                    setVideoStream(stream);
                } catch (error) {
                    console.error('Camera access denied:', error);
                    setCameraPermissionGranted(false);
                    toast.error('Camera access denied. Please allow camera access to scan QR code.', {
                        position: 'top-center',
                    });
                }
            } else {
                toast.error('Your device does not support camera access.', {
                    position: 'top-center',
                });
            }
        };

        // Propt for camera permission if not already allowed by user
        if (isTransferToModalVisible && !cameraPermissionGranted) {
            requestCameraPermission();
        }

        // Stop the camera when the modal is closed
        if (!isTransferToModalVisible && videoStream) {
            videoStream.getTracks().forEach(track => track.stop());
            setVideoStream(null);
        }
    }, [isTransferToModalVisible, cameraPermissionGranted, videoStream]);

    
    useEffect(() => {
        if (errTimer) {
            setTimeout(() => {
                setErrTimer(false);
            }, 3000);
        }
    }, [errTimer]);
    
    // Handle scan result
    const handleScan = (data) => {
        if (data && !errTimer) {
            try {
                const parsedData = JSON.parse(data.text);
                const { currentOwnerUserID, bikeID, currentOwnerUserEmail, bikeFullName } = parsedData;
                setScanResult({ currentOwnerUserID, bikeID, currentOwnerUserEmail, bikeFullName });
                toast.success('QR code scanned successfully!', {
                    position: "top-center",
                });
            } catch (error) {
                // On invalid scan, trigger re-render by changing key
                setErrTimer(true);
                setQrScannerKey(Date.now()); // Reset the QR scanner
                console.error('Invalid QR code format:', error);
                toast.error('Invalid QR code format. Please try again in 3 seconds.', {
                    position: "top-center",
                    autoClose: 3000,
                });
            }
        }
    };

    const handleError = (error) => {
        console.error('QR scan error:', error);
            toast.error(`Could not find rear camera, switching to facing camera : ${error.message}`, {
                position: "top-center",
            });
        setCameraConstraints({
            video: {
                facingMode: { ideal: 'user' },  // Switch to front camera
            }
        });
    };

    const handleCloseConfirm = () => {
        toggleModalState("transferToModal");
        setScanResult(null)
        setIsLoading(false)
    };

    const handleRequestTransfer = async (currentOwnerUserID, bikeID, newOwnerUserID, newOwnerEmail) => {
        setIsLoading(true)
        try {
            const docRef = `${currentOwnerUserID}|${newOwnerUserID}`
            await setDoc(doc(db, "pendingTransfers", docRef), {
                currentOwnerUserID: currentOwnerUserID,
                newOwnerUserID: newOwnerUserID,
                newOwnerEmail: newOwnerEmail,
                bikeID: bikeID,
                status: "pending",
            });
            await toast.success(`Request Sent`, {
                position: "top-center",
            });
        } catch(error) {
            console.error('Send Transfer request to firestore error:', error);
            toast.error(`We couldn't send this request to the current owner : ${error.message}`, {
                position: "top-center",
            });
        }

        pollDBForIncomingBike()
    };

    // Polls firebase every 5 seconds to check if a new bike has been transferred (times out after 2 mins)
    const pollDBForIncomingBike = () => {
        // 2 mins / 5 seconds = 24 loops
        let counter = 0;
        const interval = setInterval(async () => {
            if (counter >= 24) {
                clearInterval(interval);  // Stop after 24 executions
                return;
            }

            const docRef = doc(db, "Users", userDetails.uid);
            const docSnap = await getDoc(docRef);
            const newBikesArr = docSnap.data().bikes;

            // Check if there is an extra bike in newBikesArr
            if (newBikesArr.length > userDetails.bikes.length) {
                await setUserDetails(prev => {
                    return {
                        ...prev,
                        bikes: newBikesArr
                    }
                })
                await setCurrentBike(newBikesArr[0])
                await toast.success(
                    <div><strong>{newBikesArr[0].specs.make}{newBikesArr[0].specs.model !== null ? ` ${newBikesArr[0].specs.model}` : ""}</strong> has been transferred to you.</div>,
                    {
                      position: "top-center",
                    }
                  );
                await toggleModalState("transferToModal");
                counter = 24;

            }
            // Handle Timeout
            if(counter === 23) {
                setIsLoading(false)
                setScanResult(null)
                toast.error(`Request timed out, the owner took too long to respond, or denied the request.`, {
                    position: "top-center",
                });
                toggleModalState("transferToModal");
            }
            console.log(counter)
            counter++;
        }, 5000);  // Run every 5 seconds
    }


    // Switches camera from front to back
    const switchCamera = () => {
        setCameraConstraints(prevConstraints => {
            if (prevConstraints?.video?.facingMode?.ideal === 'environment') {
                return {
                    video: {
                        facingMode: { ideal: 'user' },  // Switch to front camera
                    },
                };
            } else {
                return {
                    video: {
                        facingMode: { ideal: 'environment' },  // Switch to rear camera
                    },
                };
            }
        });
    };

    return (
        <>
            <div className="modal-overlay" style={isTransferToModalVisible ? { display: "block" } : { display: "none" }}></div>
            <div className="modal-wr" id="add-bike-modal" style={isTransferToModalVisible ? { display: "block" } : { display: "none" }}>
                <div className="flex-col-center transfer-modal-wr text-center">
                    {!isLoading &&<h1 className="heading-xxl" style={{marginRight: "1em"}}>Request Bike History From Another Owner</h1>}
                    <img src={CloseModalBtn} alt="close icon" className="close-icon" onClick={handleCloseConfirm}/>
                    <>
                        {!isLoading ? 
                            <>
                                {isTransferToModalVisible && cameraPermissionGranted && !scanResult &&
                                    <>
                                        <p className="body-s text-center">You can request to transfer a bike's history from a current owner. Please scan the in-app QR code provided by the current owner</p>
                                        <img src={QRcodeIcon} alt="qr code icon" className="qr-code-icon" />
                                        <QrScanner
                                            key={qrScannerKey}  // Use qrScannerKey to re-render the component
                                            delay={300}
                                            onError={handleError}
                                            onScan={handleScan}
                                            constraints={cameraConstraints}
                                            style={{ width: '100%' }}
                                            />
                                        <button type="button" className="secondary-btn" onClick={switchCamera}>
                                            <img src={SwitchCameraIcon} alt="Switch Camera Icon" />
                                        </button>
                                    </>
                                    }
                                {scanResult && (
                                    <>
                                        <h2 className="sub-heading-orange">{scanResult?.currentOwnerUserEmail}</h2>
                                        <p>{scanResult?.bikeFullName}</p>
                                        <button className="primary-btn" onClick={() => handleRequestTransfer(scanResult?.currentOwnerUserID, scanResult?.bikeID, userDetails.uid, userDetails.email)}>Request</button>
                                    </>
                                )}
                            </>
                        :
                        <div style={{ width: '100%', display: 'flex', marginTop: "0.5em" }}>
                            <LoadingSpinner />
                        </div>}
                        {isLoading && <p style={{ marginBottom: "0.5em"}}>Waiting for the owner to repond to the request</p>}
                    </>
                </div>
            </div>
        </>
    );
}
