// Firebae Config
const firebaseConfig = {
  apiKey: "AIzaSyDyA-LU6yleA4vlHW2vI9TNHC26o21N5e4",
  authDomain: "motodiary-prod.firebaseapp.com",
  projectId: "motodiary-prod",
  storageBucket: "motodiary-prod.appspot.com",
  messagingSenderId: "593050987596",
  appId: "1:593050987596:web:8e831073a86e065f9ed8e9",
  measurementId: "G-6Z2JJSZM3H"
};

export default firebaseConfig;