import { ClipLoader } from 'react-spinners';


export default function LoadingSpinner() {
    
    const loaderStyle = {
        margin: '0 auto',
    }
    
    return (
        <ClipLoader cssOverride={loaderStyle} color={"var(--main-orange)"}/>
    )
}