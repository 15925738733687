import React from "react";
import { useState, useContext, useRef } from "react";
import { WindowContext } from "../../context/WindowContext";
import { UserContext } from "../../context/UserContext";
import CloseIcon from '../../assets/icon-cross-white.svg'
import { v4 as uuidv4 } from 'uuid';
import { getTodayDate } from "../../utils";
import { toast } from "react-toastify";
import { validateInput} from "../../utils";

export default function AddCustomBike() {
    
    const { userDetails, addBike } = useContext(UserContext);
    const {modalState, toggleModalState, setLoading, setCurrentBike} = useContext(WindowContext)
    const isAddCustomBikeVisable = modalState.addCustomBikeModal
    const [ errMsg, setErrMsg ] = useState('')
    const [input, setInput] = useState(null)
    const inputRef = useRef(null)

    // Hides Modal when 'x' is clicked
    const handleCloseModalIcon = () => {
        if (isAddCustomBikeVisable) {
            toggleModalState("addCustomBikeModal")
        }
        inputRef.current.value = ""
    }

    // Update input state when any input is changed
    const handleInputChange = () => {
        setInput(inputRef.current.value)
    }

    // Lets user continue without finding thier bike via the API
    const handleAddCustomBikeBtn = () => {
        if (validateInput(inputRef.current.value)) {
            let makeCapitalised = ""
            let modelCapitalised = ""  
            // Check if str contans more than one word
            if(input.split(" ").length > 1) {
                makeCapitalised = input.split(" ")[0].replace(/\b\w/g, char => char.toUpperCase()); 
                modelCapitalised= input.split(" ").slice(1).join(" ") 
            }
            else {
                makeCapitalised = input.replace(/\b\w/g, char => char.toUpperCase())
            }
            setLoading(true)
            try {

                // Add the new bike to users data
                const newBike = {
                    uuid: uuidv4(),
                    logs: [],
                    dashboard: {
                        bikeImagePath: "",
                        motExpiry: getTodayDate(),
                        taxExpiry: getTodayDate(),
                        tyrePressureFront: "0",
                        tyrePressureRear: "0",
                        compressionFrontHS: "0",
                        compressionRearHS: "0",
                        compressionFrontLS: "0",
                        compressionRearLS: "0",
                        reboundFrontHS: "0",
                        reboundRearHS: "0",
                        reboundFrontLS: "0",
                        reboundRearLS: "0",
                        todoList: [],
                        VIN: "",
                        registration: ""
                    },
                    specs: {
                        make: makeCapitalised,
                        model: modelCapitalised,
                        year: ""
                        }
                }
                updateUsersBikes(newBike);
                setCurrentBike(newBike);
              // Closes modal
              if (modalState.addCustomBikeModal) {
                toggleModalState("addCustomBikeModal")
              }
            }
            catch(err) {
              console.log(err)
            }
            finally {
              setLoading(false)
            }
          
        }
        else {
            toast.error("Please use a valid input. You can't leave this blank or use any special characters.", {
                position: "top-center",
              });
        }
      };

    // Push bike object to user's data
    async function updateUsersBikes(bikeObj) {
        if (!userDetails.bikes.find(bike => bike.uuid === bikeObj.uuid)) {
            addBike(bikeObj);
        }
    }

    return (
        <>
            <div className="modal-overlay" style={isAddCustomBikeVisable ? {display : "block"} : {display : "none"}}></div>
            <div className="modal-wr" id="add-bike-modal" style={isAddCustomBikeVisable ? {display : "block"} : {display : "none"}}>
                <div className="modal-header">
                    <h2 className="modal-title heading-xxl">Add Custom Bike</h2>
                    <img src={CloseIcon} alt="close icon" className="close-icon" onClick={handleCloseModalIcon}/>
                </div>
                <input type="text" className="modal-input" placeholder="Bike Name" id="add-custom-bike-input" onChange={handleInputChange} name="custom-bike-name" maxLength="100" required autoComplete="off" ref={inputRef}/>
                <button className="primary-btn" id="add-custom-bike-btn" onClick={handleAddCustomBikeBtn}>Add bike</button>   
                <p className="text-center body-s" style={{marginTop: "1em"}}>You will not have access to any manafacture specs when adding a custom bike. You should only add a custom bike if you cannot find your bike with our Bike Finder.</p>    
            </div>
        </>
    )
}