import React, { createContext, useState, useEffect} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getTodayDate } from '../utils';
import AddCustomBike from '../components/Modals/AddCustomBike';

const WindowContext = createContext();

const WindowProvider = ({ children }) => {

  // ***************************************************
  // MODALS
  // ***************************************************
  
  // Controls the visibility of all modals
  const [modalState, setModalState] = useState(
    {
      addBikeModal: false,
      addBikeNewModal: false,
      addCustomBikeModal: false,
      addNewLogModal: false,
      editLogModal: false,
      deleteConfirmLogModal: false,
      deleteConfirmBikeModal: false,
      deleteConfirmImgModal: false,
      bikeSpecsModal: false,
      addPhotoModal: false,
      enlargeImgModal: false,
      AddVINModal: false,
      feedbackModal: false,
      transferFromModal: false,
      transferToModal: false
    })
    
    // Toggles the visibility of modal 'X'
    const toggleModalState = (modalName) => {
      setModalState(prevState => ({
        ...prevState,
        [modalName]: !prevState[modalName],
      }));
      
    }
    
    const [modalPhotos, setModalPhotos] = useState([])
    
    // Tracks when the app is "Loading" / running any asyncronous tasks
    const [loading, setLoading] = useState(false)
    
    // ***************************************************
    // SIDEBAR
    // ***************************************************
    
    const [sidebarState, setSidebarState] = useState(false)
    
    // Toggles the sidebars visibility
    const toggleSidebarState = () => {
      setSidebarState(prev => !prev)
    }
    
    // ***************************************************
    // WINDOW WIDTH
    // ***************************************************
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    // Tracks whether app is in mobile mode
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    
    // Tracks width of browser window to check for mobile mode
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
        setIsMobile(window.innerWidth < 1200);
      };
      
      window.addEventListener('resize', handleResize);
      
      return () => {
        
        // Cleanup Event Listener
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    
    // ***************************************************
    // CURRENTBIKE
    // ***************************************************
    
    const [currentBike, setCurrentBike] = useState({
      uuid: "",
      logs: [],
      dashboard: {
        bikeImagePath: "",
        registration: "", 
        VIN: "",
        motExpiry: getTodayDate(),
        taxExpiry: getTodayDate(),
        tyrePressureFront: "0",
        tyrePressureRear: "0",
        compressionFrontHS: "0",
        compressionRearHS: "0",
        reboundFrontHS: "0",
        reboundRearHS: "0",
        todoList: []
      },
      specs: {make:"", model: "", year: ""}
    })
    
    // Add a new log to currentBike
    const addLog = (newLog) => {
      return new Promise((resolve, reject) => {
        try {
          setCurrentBike((prev) => {
                      const updatedBike = { ...prev, logs: [...prev.logs, newLog] }
                      resolve(updatedBike)
                      return updatedBike
                  });
                } catch (error) {
                  reject(error)
              }
          });
        }

      // Update dashboard values to currentBike
      const updateCurrentBikeDashboard = (dashboardObj) => {
        return new Promise((resolve, reject) => {
          try {
            setCurrentBike((prev) => {
              const updatedBike = {
                ...prev,
                dashboard: dashboardObj
              }
              resolve(updatedBike)
              return updatedBike
            })
            } catch (error) {
              reject(error)
            }
          }
        )}

      // Adjust Logbook margin based on header height
      const [headerHeight, setHeaderHeight] = useState("")

      // Resets currentbike after being deleted
      const resetCurrentBike = (usersBikes) => {
        console.log("run")
        // If userState.bikes is empty, set to empty bike
        if(usersBikes.length === 0) {
          setCurrentBike({
            logs: [],
            dashboard: {
              bikeImagePath: "",
              registration: "",
              VIN: "",
              motExpiry: getTodayDate(),
              taxExpiry: getTodayDate(),
              tyrePressureFront: "0",
              tyrePressureRear: "0",
              compressionFrontHS: "0",
              compressionRearHS: "0",
              compressionFrontLS: "0",
              compressionRearLS: "0",
              reboundFrontHS: "0",
              reboundRearHS: "0",
              reboundFrontLS: "0",
              reboundRearLS: "0",
              todoList: []
            },
            specs: {make:"", model: "", year: ""},
            uuid: ""
          })
        }
        
        // Set currentBike to first bike in the list
        else {
          setCurrentBike(usersBikes[0])
        }
      }
      
      // ***************************************************
      // CURRENTLOG
      // ***************************************************

      const [currentLog, setCurrentLog] = useState(
      {
        id: uuidv4(),
        date: "",
        mileage: "",
        cost: "",
        completedByShop: "No",
        tasks: [],
        notes: "",
        photos: [],
        isVerified: false
      }
    )
    
    // Updates currentLog when user clicks to edit a new log 
    const updateCurrentLog = (newLog) => {
      return new Promise((resolve, reject) => {
          try {
              setCurrentLog(() => {
                  const updatedLog = newLog
                  resolve(updatedLog)
                  return updatedLog
              });
          } catch (error) {
              reject(error)
          }
      });
    }

    const updateCurrentLogPhotos = (updatedPhotosArr) => {
      return new Promise((resolve, reject) => {
        try {
          setCurrentLog(prev => {
            const updatedLog = {
              ...prev,
              photos: updatedPhotosArr
            }
            resolve(updatedLog)
            return updatedLog
          })
        }
        catch (err) {
            console.log(err)
            reject(err)
        }
      })
    }

    const updateCurrentLogToCurrentBike = (newLog) => {
      return new Promise((resolve, reject) => {
        try {
          // get index of current log in current bike
          const indexOfCurrentLog = currentBike.logs.findIndex(log => log.id === newLog.id)
          // replace old log with new log at given index
          setCurrentBike((prev) => {
            const updatedBike = {...prev, logs: [
                ...prev.logs.slice(0, indexOfCurrentLog),
                newLog,
                ...prev.logs.slice(indexOfCurrentLog + 1)
            ]}
            resolve(updatedBike)
            return updatedBike
          })
          // return newCurrentBike
        } catch (error) {
          reject(error)
        }
      })
    }

    const deleteCurrentLogFromCurrentBike = (logToDelete) => {
      return new Promise ((resolve, reject) => {
        try {
          // filter out logToDelete from currentBike.logs
          const filteredArr = currentBike.logs.filter(log => log.id !== logToDelete.id)
          // set currentBike.logs to filtered arr
          setCurrentBike((prev) => {
            const updatedBike = {
              ...prev,
              logs: filteredArr
            }
            resolve(updatedBike)
            return updatedBike
          })
   
        }
        catch (error) {
          reject(error)
        }
      })
    } 

    const resetCurrentLog = () => {
      setCurrentLog(
        {
          id: uuidv4(),
          date: "",
          mileage: "",
          cost: "",
          completedByShop: "No",
          tasks: [],
          notes: "",
          photos: []
        }
      )
    }

    // Tracks the currently clicked on img to enlarge for user
    const [currentImgToEnlarge, setCurrentImgToEnlarge] = useState(null)

    // Track current Page the user is on
    const [currentPage, setCurrentPage] = useState("logbook")

      return (
          <WindowContext.Provider value={{ modalState, 
                                          toggleModalState, 
                                          toggleSidebarState, 
                                          sidebarState,
                                          setSidebarState, 
                                          windowWidth, 
                                          setWindowWidth,
                                          isMobile,
                                          setIsMobile,
                                          currentBike,
                                          setCurrentBike,
                                          addLog,
                                          currentLog,
                                          updateCurrentLog,
                                          updateCurrentLogPhotos,
                                          updateCurrentLogToCurrentBike,
                                          deleteCurrentLogFromCurrentBike,
                                          resetCurrentBike,
                                          loading,
                                          setLoading,
                                          headerHeight,
                                          setHeaderHeight,
                                          modalPhotos,
                                          setModalPhotos, 
                                          resetCurrentLog,
                                          currentPage,
                                          setCurrentPage,
                                          updateCurrentBikeDashboard,
                                          currentImgToEnlarge,
                                          setCurrentImgToEnlarge}}>
              {children}
          </WindowContext.Provider>
      );
}

export { WindowContext, WindowProvider }
