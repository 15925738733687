import React, { useContext, useState, useEffect } from "react";
import CloseModalBtn from '../../assets/icon-cross.svg';
import { WindowContext } from "../../context/WindowContext";
import { UserContext } from "../../context/UserContext";
import LoadingSpinner from '../Modules/LoadingSpinner';
import { QRCodeCanvas } from 'qrcode.react';
import { setDoc, doc, getDocs, collection, writeBatch, runTransaction } from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";

export default function TransferFromModal() {

    const [isLoading, setIsLoading] = useState(false);
    const { modalState, toggleModalState, currentBike, isMobile, resetCurrentBike} = useContext(WindowContext);
    const { userDetails, deleteCurrentBike} = useContext(UserContext);
    const [transferRequest, setTransferRequest] = useState(null)
    const [hasPendingRequest, setHasPendingRequest] = useState(false)
    const isTransferFromModalVisible = modalState.transferFromModal;
    
    // Here we directly use the JSON data
    const QRCodeData = JSON.stringify({
        currentOwnerUserID: userDetails.uid,
        currentOwnerUserEmail: userDetails.email,
        bikeID: currentBike.uuid,
        bikeFullName: `${currentBike.specs.make} ${currentBike.specs.model !== null ? currentBike.specs.model : ""}`
    });
    
    useEffect(() => {
        let interval;
        
        // Poll Firestore every 5 seconds for new transfer requests if the modal is visible and there's a scan result
        if (isTransferFromModalVisible) {
            interval = setInterval(() => {
                fetchTransferRequests()
            }, 5000);
        }
        
        // Cleanup the interval when the component unmounts or the modal is no longer visible
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isTransferFromModalVisible]);
    

    // Fetches User data from Firebase DB and creates it if it doesn't exist
    const fetchTransferRequests = async () => {
        return new Promise(async (resolve, reject) => {
            const querySnapshot = await getDocs(collection(db, "pendingTransfers"));
            try{
                const currentOwnerRequests = querySnapshot.docs.filter(doc => {
                    const docCurrentOwnerUserID = doc?._document?.data?.value?.mapValue?.fields?.currentOwnerUserID?.stringValue
                    const docBikeID = doc?._document?.data?.value?.mapValue?.fields?.bikeID?.stringValue
                    const requestStatus = doc?._document?.data?.value?.mapValue?.fields?.status?.stringValue
                    return docCurrentOwnerUserID === userDetails.uid && docBikeID === currentBike.uuid && requestStatus === "pending"
                })
 
                // If 1 request is returned
                if(currentOwnerRequests.length === 1) {
                    setHasPendingRequest(true)
                    setTransferRequest(currentOwnerRequests[0].data())
                
                // if no requests recieved reset requests and render qrCode
                } else if(currentOwnerRequests.length === 0) {
                    setHasPendingRequest(false)
                    setTransferRequest(null)
                    console.log("No transfer requests found")
                }
                
                // If current owner has more than one request, delete all docs for current user and tell the new owner to many requests in que, please try again
                else if(currentOwnerRequests.length > 0) {
                    throw new Error("User has more than one request for the current bike")
                }
            }
            
            // If error thrown, delete all requests for current owner and prompt to try again
            catch (err) {
                deleteAllOwnersTransferRequests()
                toast.error('You had more than 1 outstanding transfer request. Please get the requester to scan the QR code again', {
                    position: "top-center",
                    autoClose: 5000,
                });
                console.log(err)
            }
        });
    }

    const deleteAllOwnersTransferRequests = async () => {
        const querySnapshot = await getDocs(collection(db, "pendingTransfers"));
        const batch = writeBatch(db);
        querySnapshot.docs.forEach(doc => {
            const docName = doc.id; 
            if (docName.split("|")[0] === userDetails.uid) {
                batch.delete(doc.ref); // Queue the document for deletion
            }
        });
        
        // Commit the batch operation
        await batch.commit();
    };
    
    const handleCloseConfirm = () => {
        toggleModalState("transferFromModal");
    };
    
    // Transfer the history to new user using firebase transaciton - will ensures the transfer is completed or reverted
    const handleAcceptTransfer = async () => {
        await transferBikeHistory()
        deleteAllOwnersTransferRequests()
    }
    
    // Delete any transfer Requests for user
    const handleDenyTransfer = async () => { 
        deleteAllOwnersTransferRequests()
        setHasPendingRequest(false)
        toggleModalState("transferFromModal");
        toast.error(`Transfer request denied.`, {
            position: "top-center",
        })
    }

    // Transfers the bike history from the current owner to the new owner
    const transferBikeHistory = async () => {
        const {newOwnerUserID, currentOwnerUserID, bikeID} = transferRequest
        const currentOwnerRef = doc(db, 'Users', currentOwnerUserID)
        const newOwnerRef = doc(db, 'Users', newOwnerUserID)
        setIsLoading(true)
        
        // Create a backup of the bike before transfer under the users email 
        await setDoc(doc(db, "TransferBackups", userDetails.email, "bikes", currentBike.uuid), currentBike);

        // Attmempt to transfer the bike to new owner
        let updatedBikesArr = []
        try {
            await runTransaction(db, async (transaction) => {
                // Get the current owner's document
                const currentOwnerUserDoc = await transaction.get(currentOwnerRef);
                
                // Filter the target bike
                const targetBike = currentOwnerUserDoc.data().bikes.filter(bike => bike.uuid === bikeID)[0];
                
                if (!targetBike) {
                    throw new Error("Bike not found in the current owner's collection");
                }
                
                // Get the new owner's document
                const newOwnerUserDoc = await transaction.get(newOwnerRef);
                
                // Get the new owner's bikes array and push the target bike into it
                const newOwnersBikesArr = newOwnerUserDoc.data().bikes || [];
                newOwnersBikesArr.unshift(targetBike);
                
                // Update the new owner's bikes array with the updated bike list
                await transaction.set(newOwnerRef, {
                    ...newOwnerUserDoc.data(), // Make sure to use .data() to get the document data
                    bikes: newOwnersBikesArr
                });
                
                // Remove the bike from the current owner's document
                updatedBikesArr = currentOwnerUserDoc.data().bikes.filter(bike => bike.uuid !== bikeID);
                await transaction.set(currentOwnerRef, {
                    ...currentOwnerUserDoc.data(),
                    bikes: updatedBikesArr
                });
            });
            
            await toast.success(`Bike history transferred successfully!`, {
                position: "top-center",
            });
            await toggleModalState("transferFromModal");
            await setIsLoading(false)

            // set users current bike to the next bike in thier arr
            const filteredArr = userDetails.bikes.filter(bike => bike.uuid !== currentBike.uuid)
            await deleteCurrentBike(currentBike)
            await resetCurrentBike(filteredArr)
            return { success: true };
        } catch (error) {
            console.error("Something went wrong: ", error);
            await toast.error(`Something went wrong! ${error}`, {
                position: "top-center",
            });
            await toggleModalState("transferFromModal");
            await setIsLoading(false)
            return { success: false, message: error.message };
        }
    }
    
    return (
        <>
            <div className="modal-overlay" style={isTransferFromModalVisible ? { display: "block" } : { display: "none" }}></div>
            <div className="modal-wr" id="transfer-modal" style={isTransferFromModalVisible ? { display: "block" } : { display: "none" }}>
                <div className="flex-col-center transfer-modal-wr text-center">
                    <img src={CloseModalBtn} alt="close icon" className="close-icon" onClick={handleCloseConfirm}/>
                        {!isLoading ? 
                            <>
                                    {/* Render either QR code scanner or request Accept/Deny buttons based on whhether they have an incoming request */}
                                    {!hasPendingRequest ?
                                        <>
                                            <h1 className="heading-xxl" style={{color: "var(--nearly-black)", marginRight: "1em"}}>Transfer Bike History to a New Owner</h1>
                                            <h2 className="sub-heading-orange">{currentBike.specs.make} {currentBike.specs.model} {currentBike.specs.year ? `(${currentBike.specs.year})` : ""}</h2>
                                            <p style={{color: "var(--nearly-black)"}}>The new owner needs to scan this QR code to request the transfer.</p>
                                            <div className="qr-code-wr">
                                                {/* Use QRCodeCanvas with the correctly formatted QR code data */}
                                                <QRCodeCanvas value={QRCodeData} className="qr-code" size={isMobile ? 250 : 300} />
                                            </div>
                                        </> 
                                    : 
                                    <>
                                        <h1 className="heading-xxl" style={{color: "var(--nearly-black)"}}>Transfer History</h1>
                                        <p style={{color: "var(--nearly-black)"}}>You’ve had a request from <strong>{transferRequest.newOwnerEmail}</strong> to transfer the <strong>{`${currentBike.specs.make} ${currentBike.specs.model}`}’s</strong> maintenance history to them. Would you like to accept?</p>
                                        <button type="button" className='primary-btn' onClick={handleAcceptTransfer}>
                                            Accept
                                        </button>
                                        <button type="button" className='secondary-btn' onClick={handleDenyTransfer}>
                                            Deny
                                        </button>
                                    </>
                                    }
                            </>
                        :
                        <div style={{ width: '100%', display: 'flex' }}>
                            <LoadingSpinner />
                        </div>}
                </div>
            </div>
        </>
    );
}
