import React, { useContext, useState } from "react";
import CloseModalBtn from '../../assets/icon-cross-white.svg'
import { WindowContext } from "../../context/WindowContext";
import { UserContext } from "../../context/UserContext";
import { deleteAllCurrentBikeImagesFromStorage } from "../Storage"
import LoadingSpinner from '../Modules/LoadingSpinner'

export default function DeleteConfirmBikeModal() {
    
    const [deleteLoading, setDeleteLoading] = useState(false)
    const {modalState, toggleModalState, currentBike, resetCurrentBike} = useContext(WindowContext)
    const {deleteCurrentBike} = useContext(UserContext)
    const isDeleteModalVisible = modalState.deleteConfirmBikeModal
    const nameOfBike = `${currentBike.specs.make} ${currentBike.specs.model}`

    // Closes confirm modal
    const handleCloseConfirm = () => {
        toggleModalState("deleteConfirmBikeModal")
    }
    
    // Deletes the currentBike from userDetails
    const handleConfirmDelete = async () => {
        setDeleteLoading(true)
        try {
            await deleteAllCurrentBikeImagesFromStorage(currentBike)
            const updatedUserDetails = await deleteCurrentBike(currentBike)
            await resetCurrentBike(updatedUserDetails.bikes)
            toggleModalState("deleteConfirmBikeModal")
        }
        catch (err) {   
            console.log(err)
        }
        finally {
            setDeleteLoading(false)
        }
    }

    return (
            <>
                <div className="modal-overlay" style={isDeleteModalVisible ? {display : "block"} : {display : "none"}}></div>
                <div className="modal-wr" id="add-bike-modal" style={isDeleteModalVisible ? {display : "block"} : {display : "none"}}>
                
                {/* Render load spinner is still running async functions */}
                {!deleteLoading ? 
                    <>
                    <div className="delete-confirm-wr">
                        <img src={CloseModalBtn} alt="close icon" className="close-icon" onClick={handleCloseConfirm}/>
                        <h2 className="heading-xxl">{nameOfBike && nameOfBike}</h2>
                        <p className="text-center">Are you sure you want to delete this bike?</p>
                     </div>
                    <button id="confirm-delete-btn" className="primary-btn" onClick={handleConfirmDelete}>Yes</button>
                    </>
                    :
                    <div style={{
                        width: '100%',
                        display: 'flex'
                    }}>
                        <LoadingSpinner />
                    </div>}
                </div>
            </>
        )
    
}