import React, { useContext, useState } from "react";
import CloseModalBtn from '../../assets/icon-cross-white.svg'
import { WindowContext } from "../../context/WindowContext";
import { UserContext } from "../../context/UserContext";
import LoadingSpinner from '../Modules/LoadingSpinner'
import { deleteAllCurrentLogImagesFromStorage } from '../Storage'

export default function DeleteConfirmLogModal() {

    const [deleteLoading, setDeleteLoading] = useState(false)
    const {modalState, currentLog, toggleModalState, currentBike, setCurrentBike, modalPhotos} = useContext(WindowContext)
    const {updateCurrentBike, userDetails} = useContext(UserContext)
    const isDeleteModalVisible = modalState.deleteConfirmLogModal

    // Closes confirm modal and goes back to editLogModal
    const handleCloseConfirm = () => {
        toggleModalState("deleteConfirmLogModal")
      }
      
      // Deletes the log from currentBike and userState
      const handleConfirmDelete = async () => {
        setDeleteLoading(true)
        try {
          deleteAllCurrentLogImagesFromStorage(currentLog)
          const updatedCurrentBike = deleteCurrentLogFromCurrentBike(currentLog)
          await updateCurrentBike(updatedCurrentBike)
          toggleModalState("deleteConfirmLogModal")
          toggleModalState("editLogModal")
        }
        catch (err) {
          console.log(err)
        } 
        finally {
          setDeleteLoading(false)
        }

    }

    const deleteCurrentLogFromCurrentBike = (logToDelete) => {
        return new Promise ((resolve, reject) => {
          try {
            // filter out targetLog
            const filteredArr = currentBike.logs.filter(log => log.id !== logToDelete.id)
            // set currentBike.logs to filtered arr
            setCurrentBike((prev) => {
              const updatedBike = {
                ...prev,
                logs: filteredArr
              }
              resolve(updatedBike)
              return updatedBike
            })
          }
          catch (error) {
            reject(error)
          }
        })
    } 

    return (
            <>
                <div className="modal-overlay" style={isDeleteModalVisible ? {display : "block"} : {display : "none"}}></div>
                <div className="modal-wr" id="add-bike-modal" style={isDeleteModalVisible ? {display : "block"} : {display : "none"}}>

                    {/* Render load spinner if async functions are still running */}
                    {!deleteLoading ? 
                    <>
                      <div className="delete-confirm-wr">
                      <img src={CloseModalBtn} alt="close icon" className="close-icon" onClick={handleCloseConfirm}/>
                          <h2 className="heading-xl">{currentLog?.date && currentLog?.date.split("-").reverse().join("/")}</h2>
                          <p className="text-center" style={{marginBottom: "0.5em"}}>Are you sure you want to delete this log?</p>
                      </div>
                      <button id="confirm-delete-btn" className="primary-btn" onClick={handleConfirmDelete}>Yes</button>
                    </>
                    :
                    <div style={{
                      width: '100%',
                      display: 'flex'
                  }}>
                      <LoadingSpinner />
                  </div>}
                </div>
            </>
        )
    
}