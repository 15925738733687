import React from "react";
import FetchBikes from "../../fetchBikes";
import { useState, useContext, useRef } from "react";
import { WindowContext } from "../../context/WindowContext";
import { UserContext } from "../../context/UserContext";
import CloseIcon from '../../assets/icon-cross-white.svg'
import { isFirstCharNumber } from "../../utils";

export default function AddBikeNew() {
    
    const { userDetails, addBike } = useContext(UserContext);
    const {modalState, toggleModalState, setLoading, setCurrentBike} = useContext(WindowContext)
    const isAddBikeVisable = modalState.addBikeNewModal
    const [ errMsg, setErrMsg ] = useState('')
    const [input, setInput] = useState(null)
    const [fetchedData, setFetchedData] = useState('');
    const makeRef = useRef(null)
    const modelRef = useRef(null)
    const currentYear = "2022"
    const [yearListValue, setYearListValue] = useState(currentYear)
    const [newRequest, setNewRequest] = useState(false)
    const yearListRef = useRef(null);

    // Hides Modal when 'x' is clicked
    const handleCloseModalIcon = () => {
        if (isAddBikeVisable) {
            toggleModalState("addBikeNewModal")
        }
        makeRef.current.value = ""
        modelRef.current.value = ""
    }

    // Update input state when any input is changed
    const handleInputChange = () => {
        setNewRequest(true)
        let make = makeRef.current.value
        let model = modelRef.current.value

        // delete model input string if starts with number as API throws an err
        if (isFirstCharNumber(model[0])) {
            model = ""
            setErrMsg("Do not use the engine capacity for the model. For example: enter 'KTM Adventure', NOT 'KTM 890'")
        }
        else {
            setErrMsg("")
        }   
        setInput(`${make} ${model}`)
    }

    // Gets Bike data from fetchBikes.js
    const handleFetchedData = (data) => {
        setFetchedData(data)
    }
    
    // Lets user continue without finding thier bike via the API
    const handleContinueWithoutBtn = () => {
        toggleModalState("addBikeNewModal")
        toggleModalState("addCustomBikeModal")
      };

    // Push bike object to user's data
    async function updateUsersBikes(bikeObj) {
        if (!userDetails.bikes.find(bike => bike.uuid === bikeObj.uuid)) {
            addBike(bikeObj);
        }
    }
  
    // Create a list of years for the year selection dropdown 
    let listOfYears = []
    for(let i = parseInt(currentYear); i >= 1960; i--) {
        listOfYears.push(i)
    }
    const yearListJSX = listOfYears.map(year => {
            return (
                <option value={year} key={year}>{year}</option>
            )
    })

    // Change the year value when the user changes the year selection dropdown
    const handleYearChange = (e) => {
        setYearListValue(e.target.value);
        setNewRequest(true)
    };

    return (
        <>
            <div className="modal-overlay" style={isAddBikeVisable ? {display : "block"} : {display : "none"}}></div>
            <div className="modal-wr" id="add-bike-modal" style={isAddBikeVisable ? {display : "block"} : {display : "none"}}>
                <div className="modal-header">
                    <h2 className="modal-title heading-xxl">Bike Finder</h2>
                    <img src={CloseIcon} alt="close icon" className="close-icon" onClick={handleCloseModalIcon}/>
                </div>
                <form>
                    <div className="add-bike-input-wr">
                        <h3 className="heading-m">Make</h3>
                        <input type="text" className="modal-input" placeholder="Kawasaki" id="add-bike-make-input" onChange={handleInputChange} name="make" maxLength="100" required autoComplete="off" ref={makeRef}/> 
                        <h3 className="heading-m">Model</h3>
                        <input type="text" className="modal-input" placeholder="Ninja" id="add-bike-model-input" onChange={handleInputChange} name="model" maxLength="100" required autoComplete="off" ref={modelRef}/> 
                        {errMsg !== '' && <p className="err-msg body-s model-err">{errMsg}</p>}
                        {yearListJSX && <select
                            id="bike-year"
                            name="bike-year"
                            value={yearListValue}
                            onChange={handleYearChange}
                            className="dropdown-list"
                            ref={yearListRef}
                        >
                            {yearListJSX}
                        </select>}
                    {/* Fetch bike from API */}
                    {input &&
                        <FetchBikes
                            input={input}
                            makeRef={makeRef}
                            year={yearListValue}
                            onFetchedData={handleFetchedData}
                            setNewRequest={setNewRequest}
                            newRequest={newRequest}
                        />
                    }
                    </div>
                    <p className="err-msg body-s text-center">We're currently using a free API service for our bike data, which may have some limitations. As a result, the latest models available are from 2022.</p>
                        <button className="secondary-btn" id="cannot-find-btn" onClick={handleContinueWithoutBtn}>Cannot find your bike?</button>
                </form>
            </div>
        </>
    )
}