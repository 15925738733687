import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// src/firebaseConfig.js

let firebaseConfig = require('./firebaseConfig.dev').default;

if (process.env.REACT_APP_FIREBASE_ENV === 'production') {
    firebaseConfig = require('./firebaseConfig.prod').default;
}

console.log("Using Firebase Config:", process.env.REACT_APP_FIREBASE_ENV);

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore(app);
export default app;