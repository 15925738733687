import React from 'react';
import GotoIcon from '../../assets/icon-go-to.svg'


export const SearchManualsLib = ({ bike }) => {
  const baseUrl = bike ? `https://www.manua.ls/?q=` : null;
  const bikeMake = bike.specs.make.toLowerCase()
  const bikeModel = bike.specs.model?.toLowerCase().split(" ").join("+")
  const searchUrl = baseUrl ? `${baseUrl}${bikeMake}+${bikeModel}` : null;
  

  return (
    <div>
      <a href={searchUrl} target="_blank" rel="noopener noreferrer" className='manual-link body-l'>
        Find Manual
        <img src={GotoIcon} alt="go to icon" />
      </a>
    </div>
  );
};