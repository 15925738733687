import React from "react"
import { Outlet } from "react-router-dom"
import Header from './Header'
import Main from './Main'
import SideBar from './SideBar'

export default function Layout() {
    return (
        <>
            {/* Whole App Layout */}
            <SideBar />
            <Main> 
                <Outlet />
            </Main>
            <Header />
        </>
    )
}