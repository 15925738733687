import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, db } from '../../firebase';
import { doc, setDoc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { toast } from "react-toastify";

const provider = new GoogleAuthProvider();

const handleSignIn = async () => {
  try {
    // Initiate sign-in using a pop-up window
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    
    if(user) {
      
      // Check if user already exists
      const userRef = doc(db, "Users", user.uid);
      const userSnap = await getDoc(userRef);
      if (!userSnap.exists()) {
        // Increment user count
        const userCountRef = doc(db, "siteData", "userCount");
        await updateDoc(userCountRef, {
          count: increment(1)
        });
      }
      
      
      // Direct user to /profile
      window.location.href = "/profile/logbook";
      toast.success("User logged in successfully", {
        position: "top-center",
      });
    }
    
    // Display a success message or redirect to another page
  } catch (error) {
    console.error('Error during sign-in:', error);
    // Handle errors, such as by displaying a message to the user
  }
};

export default function GoogleSignIn() {
  return (
    <button type="button" className="login-with-google-btn" onClick={handleSignIn} >
  Sign in with Google
    </button>
  );
}
