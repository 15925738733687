import React, { useRef, useState, useEffect } from 'react';
import CameraIcon from '../../assets/icon-camera-fill.svg';
import SwitchCameraIcon from '../../assets/icon-switch-camera.svg';
import CameraIcons from '../../assets/icon-camera.svg'
import { toast } from "react-toastify";

const CapturePhoto = ({ onCapture }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [cameraAccess, setCameraAccess] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(false); // Default to back-facing camera

  // Starts the camera stream
  const startCamera = async (facingMode = 'environment') => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    try {
      const newStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { exact: facingMode } },
      });
      setStream(newStream);
      setCameraAccess(true);
    } catch (error) {
      console.error('Error accessing the camera. Trying to fallback to front camera:', error);
      toast.error("Sorry! No Camera found on this device.", {
        position: "top-center",
      });

      // Fallback to front camera if back camera is not available
      if (facingMode === 'environment') {
        startCamera('user');
      } else {
        console.error('Failed to access front camera as well:', error);
      }
    }
  };

  // Start camera stream
  useEffect(() => {
    if (cameraAccess && videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [cameraAccess, stream]);

  const takePhoto = async () => {
    const originalWidth = videoRef.current.videoWidth;
    const originalHeight = videoRef.current.videoHeight;

    // Helper function to resize and get data URL while maintaining aspect ratio
    const resizeImage = (maxWidth, maxHeight, quality) => {
      const aspectRatio = originalWidth / originalHeight;
      let width = maxWidth;
      let height = maxHeight;

      // Adjust dimensions to maintain aspect ratio
      if (originalWidth > originalHeight) {
        height = Math.round(maxWidth / aspectRatio);
      } else {
        width = Math.round(maxHeight * aspectRatio);
      }
      canvasRef.current.width = width;
      canvasRef.current.height = height;
      const context = canvasRef.current.getContext('2d');
      context.clearRect(0, 0, width, height);
      context.drawImage(videoRef.current, 0, 0, width, height);

      return canvasRef.current.toDataURL('image/jpeg', quality);
    };

    // Create small image URL (maintaining aspect ratio within 800x600)
    const smallDataUrl = resizeImage(800, 600, 0.5); // 50% quality

    // Calculate large image dimensions while maintaining aspect ratio within 2560x1440
    const maxLargeWidth = 2560;
    const maxLargeHeight = 1440;
    let largeWidth = originalWidth;
    let largeHeight = originalHeight;

    // Scale down large image if necessary
    if (originalWidth > maxLargeWidth || originalHeight > maxLargeHeight) {
      const largeScale = Math.min(maxLargeWidth / originalWidth, maxLargeHeight / originalHeight);
      largeWidth = Math.round(originalWidth * largeScale);
      largeHeight = Math.round(originalHeight * largeScale);
    }

    // Create large image URL with scaled dimensions and 75% quality
    const largeDataUrl = resizeImage(largeWidth, largeHeight, 0.75);

    // Pass the compressed data URLs to the onCapture function
    onCapture(smallDataUrl, largeDataUrl, true);

    // Stop the camera
    stopCamera();
  };

  // Stop camera stream
  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    setCameraAccess(false);
    setStream(null);
  };

  // Switches camera from front to back
  const switchCamera = () => {
    setIsFrontCamera(!isFrontCamera);
    startCamera(isFrontCamera ? 'environment' : 'user');
  };

  return (
    <div>
      {/* If the user has granted access, display the video stream ready to take a photo */}
      {!cameraAccess && (
        <button type="button" className="primary-btn" onClick={() => startCamera('environment')}>
          <img className="button-icon" alt="camera icon" src={CameraIcons} />
          Start Camera
        </button>
      )}
      {cameraAccess && (
        <>
          <div className="video-frame">
            <video ref={videoRef} autoPlay playsInline width="100%" height="auto" />
          </div>
          <div className="flex-btns-wr">
            <button type="button" className="primary-btn" onClick={takePhoto}>
              <img src={CameraIcon} alt="Camera Icon" />
            </button>
            <button type="button" className="secondary-btn" onClick={switchCamera}>
              <img src={SwitchCameraIcon} alt="Switch Camera Icon" />
            </button>
          </div>
        </>
      )}
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default CapturePhoto;
