import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import TransferLogToIcon from '../../assets/icon-transfer-to.svg'
import BikeIconWhite from '../../assets/icon-bike-white.svg'
import BikeIconGrey from '../../assets/icon-bike-grey.svg'
import CrossIcon from '../../assets/icon-cross-white.svg'
import { WindowContext } from "../../context/WindowContext";
import { UserContext } from "../../context/UserContext";
import {auth} from '../../firebase'

export default function SideBar() {
    
    const { sidebarState, toggleSidebarState, setSidebarState, toggleModalState, isMobile, setCurrentBike, currentBike, setLoading, currentPage} = useContext(WindowContext) 
    const { userDetails } = useContext(UserContext)  
    const bikeCount = userDetails.bikes.length

    // Changes currentBike when user clicks on another bike
    const handleChangeCurrentBike = async (e) => {
        setLoading(true)
        const clickedBikeObj = userDetails.bikes.filter(bike => bike.uuid === e.target.id)[0]
        await setCurrentBike(clickedBikeObj)
        setLoading(false)
        toggleSidebarState()
    }
    
    // Create bike list JSX for sidebar
    let bikeListJSX = null
    try {
            bikeListJSX = userDetails.bikes.map(bike => {
            return (
                <button className={`bike-btn ${bike.uuid === currentBike.uuid && "selected"}`} key={bike.uuid} id={bike.uuid} onClick={handleChangeCurrentBike}>
                        <img src={bike.uuid === currentBike.uuid ? BikeIconWhite : BikeIconGrey} alt="bike icon" className="bike-icon"/>
                        {bike.specs.make} {bike.specs.model}   
                </button>
            )    
        })
    } catch(error) {
        console.log(error)
    }
    
    // Prompts user to add bike when btn is clicked
    const handleAddBikeBtn = () => {
        toggleModalState("addBikeNewModal")
        toggleSidebarState()
    }

    // Closes sidebar
    const handleCloseSidebar = () => {
        toggleSidebarState()
    }
    
    // If user is in desktop mode, Sidebar is displayed permanently
    if (!isMobile) {
        setSidebarState(true)
    }

    // Displays confirm modal for user to delete bike
    const handleDeleteBikeConfirm = () => {
        toggleModalState("deleteConfirmBikeModal")
    }
    
    // Logs user out of app
    async function handleLogout() {
        try {
            await auth.signOut();
            window.location.href = "/login";
        } catch (error) {
            console.error("Error logging out:", error.message);
        }
    }

    // Directs user to Dashboard page
    const handleClickDashboardBtn = () => {
        toggleSidebarState()
    }

    // Directs user to Logbook page
    const handleClickLogbookBtn = () => {
        toggleSidebarState()
    }

    // Displays feedback modal
    const handleFeedbackBtn = () => {
        toggleModalState("feedbackModal")
    }
    
    // Displays feedback modal
    const handleAboutBtn = () => {
        toggleSidebarState()
    }

    // Shows the TransferToModal where users can scan a QR code to request a bikes history
    const handleShowTransferToModal = async (photo) => {
        toggleModalState("transferToModal")
    }

    return (

        // Dipslay sidebar base on display state 
        <section className={`${sidebarState ? "sidebar-wr show" : "sidebar-wr"}`}>
            <div className="top-row">
                <div className="sidebar-nav-wr">
                    <nav className="sidebar-nav-btns">
                        {!(bikeCount === 0) && <Link className="nav-btn" style={currentPage === "dashboard" ? {color: "var(--main-orange)"} : {color: "var(--grey-2)"}} onClick={handleClickDashboardBtn} to="/profile/dashboard">
                            DASHBOARD  
                        </Link>}
                        <Link className="nav-btn" style={currentPage === "logbook" ? {color: "var(--main-orange)"} : {color: "var(--grey-2)"}} onClick={handleClickLogbookBtn} to="/profile/logbook">
                            LOGBOOK
                        </Link>
                        <Link className="nav-btn" onClick={handleFeedbackBtn}>FEEDBACK</Link>
                        <Link className="nav-btn" style={currentPage === "about" ? {color: "var(--main-orange)"} : {color: "var(--grey-2)"}} to="/profile/about"  onClick={handleAboutBtn}>
                            ABOUT
                        </Link>
                        <Link className="nav-btn donate-btn" style={currentPage === "donate" ? {color: "var(--main-orange)"} : {color: "var(--main-orange-hover)"}} to="/profile/support-us"  onClick={handleAboutBtn}>
                            SUPPORT US
                        </Link>
                    </nav>
                    {/* Only Show cross btn in mobile view */}
                    {isMobile && sidebarState && <img src={CrossIcon} alt="cross icon" className="close-sidebar-icon" onClick={handleCloseSidebar}/>}
                </div>
                <div className="bike-list">
                    <p className="bike-count heading-s">ALL BIKES ({bikeCount ? bikeCount : 0}/3) </p>
                    {currentBike && bikeListJSX}
                    {bikeCount < 3 && 
                        <>
                            <button className="request-bike-btn" onClick={handleShowTransferToModal}>
                            <img src={TransferLogToIcon} alt="transfer log icon" className="sidebar-transfer-log-icon" />
                                Request Bike History
                            </button>
                            <button className="add-bike-btn" onClick={handleAddBikeBtn}>
                                + Add Bike
                            </button>
                        </>
                    }
                </div>
            </div>
            <div className="bottom-row"> 
                <div className="bike-btn-row">
                    {/* Only show Delete btn if there are bikes to delete */}
                    {bikeCount > 0 && <button className="delete-bike-btn" onClick={handleDeleteBikeConfirm}>
                        Delete Bike
                    </button>}
                </div>
                <div className="theme-selector-signout-wr">
                    <button id="signout-btn" onClick={handleLogout}>Sign Out</button>
                </div>
                <p className="body-s text-center">{userDetails.email}</p>
            </div>
        </section>
    )
}