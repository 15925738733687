import React, { useState, useEffect, useContext, useRef } from 'react';
import { UserContext } from './context/UserContext';
import { WindowContext } from './context/WindowContext';
import { v4 as uuidv4 } from 'uuid';
import { fetchData } from './utils';
import LoadingSpinner from './components/Modules/LoadingSpinner';
import { toast } from "react-toastify";
import { getTodayDate, validateInput } from './utils';

function FetchBikes({ input, year}) {
  
  const { modalState, toggleModalState, setCurrentBike, setLoading } = useContext(WindowContext);
  const { userDetails, addBike } = useContext(UserContext);
  const [make, setMake] = useState(null);
  const [bikeModels, setBikeModels] = useState(null);
  const [bikeYears, setBikeYears] = useState(null);
  const [selectedBikeModel, setSelectedBikeModel] = useState(null);
  const [modelsDropdownJSX, setModelsDropdownJSX] = useState(null);
  const [yearsDropdownJSX, setYearsDropdownJSX] = useState(null);
  const [modelDropdownValue, setModelDropdownValue] = useState(null);
  const [yearDropdownValue, setYearDropdownValue] = useState(null);
  const [selectedBike, setSelectedBike] = useState(null);
  const [loadingModels, setLoadingModels] = useState(false);
  const modelDropdownRef = useRef(null);

  // Reset modal if input is changed by user
  useEffect(() => {
    if (bikeModels) {
      setMake(null);
      setBikeModels(null);
      setBikeYears(null);
      setSelectedBikeModel(null);
      setModelsDropdownJSX(null);
      setYearsDropdownJSX(null);
      setModelDropdownValue(null);
      setYearDropdownValue(null);
      setSelectedBike(null);
    }
  }, [input, year]);

  // Finds all the bike models from users input
  const handleFindModel = () => {

    // Check for valid input
    if (validateInput(input)) {
      if (input) {
        const make = input.split(" ")[0];
        setMake(make);
        const model = input.split(" ").slice(1).join(" ");
        const url = `https://api.api-ninjas.com/v1/motorcycles?make=${make}&model=${model}&year=${year}`;
        setLoadingModels(true);
        fetchData(url)
          .then(async (result) => {
            try {
              setBikeModels(result);
              setModelDropdownValue(result[0].model);
              setSelectedBikeModel(result[0]);
              setSelectedBike(updateSelectedBike(result[0]))
              setModelsDropdownJSX(result.map(bike => (
                <option value={bike.model} key={uuidv4()}>{bike.model}</option>
              )));
            } catch (err) {
              setLoadingModels(false);
              toast.error(`Cannot find any bikes called: ${input}`, {
                position: "top-center",
              });
            } finally {
              setLoadingModels(false);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Sorry! We are not able to fetch bikes from our provider at the moment.", {
              position: "top-center",
            });
          });
      }
    }
    else {
      toast.error("Sorry! You can't use any special characters", {
        position: "top-center",
      });
    }
  };

  // When the user changes the model selection dropdown
  const handleModelChange = (e) => {
    const bikeObj = bikeModels.filter(bike => bike.model === e.target.value)[0];
    setModelDropdownValue(bikeObj.model);
    setSelectedBike(updateSelectedBike(bikeObj));
  };
  
  const updateSelectedBike = (bikeObj) => {
    const updatedBike = {
      uuid: uuidv4(),
      logs: [],
      dashboard: {
        bikeImagePath: "",
        registration: "",
        VIN: "",
        motExpiry: getTodayDate(),
        taxExpiry: getTodayDate(),
        tyrePressureFront: "0",
        tyrePressureRear: "0",
        compressionFrontHS: "0",
        compressionRearHS: "0",
        compressionFrontLS: "0",
        compressionRearLS: "0",
        reboundFrontHS: "0",
        reboundRearHS: "0",
        reboundFrontLS: "0",
        reboundRearLS: "0",
        todoList: []
      },
      specs: { ...bikeObj },
    };
    return updatedBike;
  };
  
  // Adds bike to user's profile
  const handleClickAddBikeBtn = () => {
    setLoading(true);
    if (selectedBike) {
      try {
        updateUsersBikes(selectedBike);
        setCurrentBike(selectedBike);
        
        // Closes modal
        if (modalState.addBikeNewModal) {
          toggleModalState("addBikeNewModal");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };
  
  // Push bike object to user's data
  async function updateUsersBikes(bikeObj) {
    if (!userDetails.bikes.find(bike => bike.uuid === bikeObj.uuid)) {
      addBike(bikeObj);
    }
  }

  return (
    <>
      {input !== "" && (
        <>
          {bikeModels && modelDropdownValue && !loadingModels &&  (
            <div className="modal-model-wr">
              <h3 className="heading-m">Choose a Model:</h3>
              <select
                id="bike-model"
                name="bike-model"
                value={modelDropdownValue}
                onChange={handleModelChange}
                className="dropdown-list"
                ref={modelDropdownRef}
              >
                {modelsDropdownJSX}
              </select>
            </div>
          )}
        </>
      )}
      {loadingModels && (
        <div style={{ width: '100%', display: 'flex' }}>
          <LoadingSpinner />
        </div>
      )}
      {selectedBike === null && !loadingModels &&(
        <button
          type="button"
          className="primary-btn"
          id="find-model-btn"
          onClick={handleFindModel}
        >
          Find Model
        </button>
      )}
      
      {selectedBike && !loadingModels &&(
        <button
          type="button"
          className="primary-btn"
          id="add-bike-btn"
          onClick={handleClickAddBikeBtn}
        >
          Add Bike
        </button>
      )}
    </>
  );
}

export default FetchBikes;
