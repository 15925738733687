import React, { createContext, useState } from 'react';

const UserContext = createContext();

// Provider component
const UserProvider = ({ children }) => {

    // This holds all the data for the current user
    const [userDetails, setUserDetails] = useState(null);
    
    // Adds a new bike to userDetails
    const addBike = async (bike) => {     
        if (userDetails && userDetails.bikes && !userDetails.bikes.find(bikeObj => bikeObj.uuid === bike.uuid)) {
            setUserDetails(prevState => ({
                ...prevState,
                bikes: [...prevState.bikes, bike]
            }));
        }
    };

    // Deletes current bike from userDetails
    const deleteCurrentBike = (bike) => {
        return new Promise ((resolve, reject) => {
            try {
                if (userDetails && userDetails.bikes && userDetails.bikes.find(bikeObj => bikeObj.uuid === bike.uuid)) {
                    const filteredArr = userDetails.bikes.filter(bikeObj => bikeObj.uuid !== bike.uuid);
                    setUserDetails((prevState) => {
                        const updatedUserDetails = {
                        ...prevState,
                        bikes: [...filteredArr]
                    }
                    resolve(updatedUserDetails)
                    return updatedUserDetails
                });
                }
            } 
            catch (err) {
                reject(err)
                console.log("There was a problem deleting currentBike")
            }
        })
    };

    // Updates the current bike in userDetails
    const updateCurrentBike = (currentBike) => {
        if (userDetails && userDetails.bikes) {
            setUserDetails((prev) => {
                const bikeIndex = prev.bikes.findIndex(bike => bike.uuid === currentBike.uuid);
                if (bikeIndex === -1) {
                    return prev;
                }
                // Create a new array with the updated bike
                const updatedBikes = [
                    ...prev.bikes.slice(0, bikeIndex),
                    currentBike,
                    ...prev.bikes.slice(bikeIndex + 1)
                ];

                return {
                    ...prev,
                    bikes: updatedBikes
                };
            });
        }
    };

    return (
        <UserContext.Provider value={{ userDetails, setUserDetails, addBike, deleteCurrentBike, updateCurrentBike }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };
