import Logo from '../../assets/logo-pack/logo-dark-small.svg'

function VerifyEmailPrompt({user}) {

  return (
    <div className="auth-wrapper">
      <div className="auth-inner  center-text">
        <div className="modal-header">
          <img src={Logo} alt="logo" className="auth-logo"/>
        </div>
        <p>Please go to <strong style={{color: "var(--grey-2)"}}>{user && user.email}</strong> and verify your account.</p>
        <p className="bold" style={{marginTop: "1em"}}>Be sure to check your junk email!</p>
        <p className="forgot-password body-l">
          Back to Login <a href="/login">Click Here</a>
        </p>
      </div>
    </div>
  );
}

export default VerifyEmailPrompt;
