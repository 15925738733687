import React, { useState } from "react";
import { useContext } from "react";
import { WindowContext } from "../../context/WindowContext";
import CloseIcon from '../../assets/icon-cross-white.svg'
import CameraIcon from '../../assets/icon-camera.svg'
import GalleryIcon from '../../assets/icon-gallery.svg'
import VerifyIcon from '../../assets/icon-photo-is-verified.svg'
import CapturePhoto from "./CapturePhoto";
import UploadPhoto from "./UploadPhoto";
import { uploadLogImage, uploadBikeImage} from '../Storage';
import LoadingSpinner from '../Modules/LoadingSpinner'
import { getTodayDate } from "../../utils";

export default function AddPhotoModal() {
    
    const {modalState, toggleModalState, setModalPhotos, currentLog, currentPage, setCurrentBike, currentBike} = useContext(WindowContext)
    const isAddPhotoVisable = modalState.addPhotoModal
    const [ uploadType, setUploadType ] = useState(null)
    const [ loadingPhoto, setLoadingPhoto ] = useState(false)

    // Hides Modal when 'x' is clicked
    const handleCloseModalIcon = () => {
        if (isAddPhotoVisable) {
            toggleModalState("addPhotoModal")
        }

        setUploadType(null)
    }

    const handlePhoto = async (smallDataUrl, largeDataUrl, isCaptureFromCamera) => {        
        if(largeDataUrl && smallDataUrl) {
            setLoadingPhoto(true)
            if(currentPage === "logbook") {
                try {
                    // - Upload Image to Firebase storage
                    const smallImageURL = await uploadLogImage( smallDataUrl, "small", currentBike.uuid, currentLog.id)
                    const largeImageURL = await uploadLogImage( largeDataUrl, "large", currentBike.uuid, currentLog.id)     
                    if(smallImageURL && largeImageURL) {
                        // Push new photo to photos arr
                        setModalPhotos(prev => {
                            // If img is captured with camera, issue a datestamp
                            const datestamp = isCaptureFromCamera ? getTodayDate() : null
                            return [
                                ...prev,
                                // Image URL to link to firebase storage
                                {
                                    smallImageURL: smallImageURL,
                                    largeImageURL: largeImageURL,
                                    isVerified: false,
                                    datestamp: datestamp
                                }
                            ]
                        })
                    }
                    setUploadType(null)
                } 
                catch (err) {
                    console.log(err)
                }
                finally {
                    setLoadingPhoto(false)
                }
            }
            else if (currentPage === "dashboard") {
                try {
                    // - Upload Image to Firebase storage
                    const smallImageURL = await uploadBikeImage(smallDataUrl, currentBike.uuid)
                    setCurrentBike((prev) => {
                        const updateBike = {
                            ...prev,
                            dashboard: {
                                ...prev.dashboard,
                                bikeImagePath: smallImageURL
                            }
                        }
                        return updateBike
                    })
                    setUploadType(null)  
                } 
                catch (err) {
                    console.log(err)
                }
                finally {
                    setLoadingPhoto(false)
                }
            }
        }
        
        // Close addPhotoModal
        if (isAddPhotoVisable) {
            toggleModalState("addPhotoModal")
        }
    };
    
    return (
        
        <>
            <div className="modal-overlay overlap" style={isAddPhotoVisable ? {display : "block"} : {display : "none"}}></div>
            <div className="modal-wr overlap" id="add-photo-modal" style={isAddPhotoVisable ? {display : "block"} : {display : "none"}}>
            {!loadingPhoto ? 
                <>
                        <div className="modal-header">
                            <h3 className="heading-xl no-marg">Add a Photo</h3>
                            <img src={CloseIcon} alt="close icon" className="close-icon" onClick={handleCloseModalIcon}/>
                        </div>
                        {uploadType === null && 
                        <>
                            <button type="button" className='primary-btn' onClick={() => {setUploadType("camera")}}>
                                <img className="button-icon" alt="camera icon" src={CameraIcon} />
                                Take Photo
                            </button>
                            <button type="button" className='secondary-btn' onClick={() => {setUploadType("gallery")}}>
                                <img className="button-icon" alt="Gallery icon" src={GalleryIcon} />
                                Upload Photo
                            </button>
                        </>}

                        {/* Render camera stream */}
                        {uploadType === "camera" && 
                        <CapturePhoto onCapture={handlePhoto} />}

                        {/* Render Upload Photo */}
                        {uploadType === "gallery" && 
                        <UploadPhoto onUpload={handlePhoto} setLoadingPhoto={setLoadingPhoto}/>}
                        <p className="text-center body-s" style={{"marginTop": "1em" }}><strong>TIP:</strong> We verify (<img src={VerifyIcon}  alt="verify-icon"/>) any logs where all photos have been taken with the <strong>Take Photo (Camera)</strong> on the same day as the <strong>Log Date</strong>. This garuntees all photos have been taken in real-time and not forged</p>
                </>
            // Display Loading Spinnner if photo is still uploading 
            : <div style={{
                width: '100%',
                display: 'flex'
            }}>
                <LoadingSpinner />
            </div>}
            </div>
        </>
    )
}