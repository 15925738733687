import React from "react";

export default function InfoToolTip() {
    return (
        <div className="tooltip-wr">
            <p className="tooltip-info">
                The Maintenance Log Book allows you to record your bike maintenance, whether you've done it yourself, or sent it off to a dealer. Click <strong>'Add New Service Log'</strong> to get started!
            </p>
        </div>    
    )
}