import React, { useContext } from "react";
import CloseModalBtn from '../../assets/icon-cross-white.svg'
import { WindowContext } from "../../context/WindowContext";
import LoadingSpinner from '../Modules/LoadingSpinner'

export default function DeleteConfirmImgModal({ setConfirmedDelete, confirmedDelete, setDeleteImgURL }) {
    
    const {modalState, toggleModalState } = useContext(WindowContext)
    const isDeleteModalVisible = modalState.deleteConfirmImgModal

    // Closes confirm modal and resets URL and confirm state
    const handleCloseConfirm = () => { 
        toggleModalState("deleteConfirmImgModal")      
        setConfirmedDelete(false)    
        setDeleteImgURL("")   
    }
    
    // Tells EditLog that the user has confirmed they want to delete Img 'X'
    const handleConfirmBtn = () => {
            setConfirmedDelete(true)
    }
    
    return (
        <>
            {!confirmedDelete ? <>
                <div className="modal-overlay overlap" style={isDeleteModalVisible ? {display : "block"} : {display : "none"}}></div>
                <div className="modal-wr overlap" id="add-bike-modal" style={isDeleteModalVisible ? {display : "block"} : {display : "none"}}>
                    <div className="delete-confirm-wr">
                    <img src={CloseModalBtn} alt="close icon" className="close-icon" onClick={handleCloseConfirm}/>
                        <h2 className="heading-l">Delete Photo</h2>
                        <p className="center-text">Are you sure you want to delete this photo?</p>
                    </div>
                        <button id="confirm-delete-btn" className="primary-btn" onClick={handleConfirmBtn}>Yes</button>
                </div>
            </> : 
                <div style={{
                    width: '100%',
                    display: 'flex'
                }}>
                    <LoadingSpinner />
                </div>}
        
        </>
        )
}