import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import React, { useState } from "react";
import { auth, db } from "../../firebase";
import { setDoc, doc, increment, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import Logo from '../../assets/logo-pack/logo-dark-small.svg'
import LoadingSpinner from '../Modules/LoadingSpinner'
import FirebaseAuthImg from '../../assets/firebase-auth-img.svg'

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false)

  // Check passwords match
  const checkConfirmPassword = () => {
    if (password === confirmPassword) {
      return true
    }
    else {
      toast.error("Passwords do not match", {
        position: "top-center",
      });
      return false
    }
  }

  const handleRegister = async (e) => {
    e.preventDefault();
    if(checkConfirmPassword()) {  
      setLoading(true)
      try {   
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        if (user) {

          // Send email verification
          await sendEmailVerification(user);
          
          // Create new user in firebase
          await setDoc(doc(db, "Users", user.uid), {
            uid: user.uid,
            email: user.email,
            bikes: [],
            themeIsDark: true,
            photo: ""
          });

          // Increment the user count in Firestore
          const userCountRef = doc(db, "siteData", "userCount");
          await updateDoc(userCountRef, {
            count: increment(1)
          });

          toast.success("User Registered Successfully! Please verify your email.", {
            position: "top-center",
          });
          window.location.href = "/verify-email";
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
        });
      }
      finally {
        setLoading(false)
      }
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <div className="modal-header">
          <img src={Logo} alt="logo" className="auth-logo"/>
        </div>
        <form onSubmit={handleRegister}>
          <div className="modal-email-wr">
            <h3 className="heading-m">Email address</h3>
            <input
              type="email"
              className="modal-input"
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
              required
              autoComplete="username"
            />
          </div>
          <div className="modal-password-wr">
            <h3 className="heading-m">Password</h3>
            <input
              type="password"
              className="modal-input"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="current-password"
            />
          </div>
          <div className="modal-password-wr">
            <h3 className="heading-m"> Confirm Password</h3>
            <input
              type="password"
              className="modal-input"
              placeholder="Enter password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="auth-footer">
            {!loading ? <button type="submit" className="primary-btn">
              Sign Up
            </button>
            :
            <div style={{
              width: '100%',
              display: 'flex'
            }}>
              <LoadingSpinner />
            </div>
            }
            <p className="forgot-password body-l">
              Already registered? <a className="body-l" href="/login">Login Here</a>
            </p>
          </div>
          <div className="firebase-auth-img-wr">
            <img className="firebase-auth-img" src={FirebaseAuthImg} alt="firebase auth image" />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
