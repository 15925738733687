import React, { useEffect } from "react";
import { useContext, useRef } from "react";
import DarkLogo from '../../assets/logo-pack/logo-dark-small.svg'
import IconMenu from '../../assets/icon-menu.svg'
import { WindowContext } from "../../context/WindowContext";

export default function Header() {
    const { toggleSidebarState, currentBike, setHeaderHeight, currentPage} = useContext(WindowContext)
    const headerRef = useRef(null)
    let headerTitle = ""

    // Render correct title based on current page
    if(currentPage === "dashboard") {
        headerTitle = "Dashboard"
    }
    else if (currentPage === "logbook") {
        headerTitle = "Maintenance History"
    }

    // If app is in mobile mode - clicking menu icon toggles the display of the sidebar
    const handleMenuIcon = () => {
        toggleSidebarState()
    }

    // Calculate header height based on viewport width
    useEffect(() => {
        const updateHeaderHeight = () => {
            if(headerRef.current) {
                setHeaderHeight(headerRef.current.offsetHeight)
            }
        }
        updateHeaderHeight()
        // Update header height on window resize
        window.addEventListener('resize', updateHeaderHeight);
        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('resize', updateHeaderHeight);
        };
    })
    
    return (
        <header ref={headerRef}>
            <div className="left-col">
                <img src={DarkLogo} alt="logo" className="logo" />
                <p className="header-subtitle">{currentBike !== "" && currentBike.specs.model}</p>
            </div>
            <div className="right-col">
                <h1 className="header-title heading-xl">{headerTitle}</h1>
                <p></p>
                <img src={IconMenu} alt="info menu" className="menu-icon" onClick={handleMenuIcon}/>
            </div>
        </header>
    )
}