import React, { useContext } from "react";
import { WindowContext } from "../../context/WindowContext";
import CloseIcon from '../../assets/icon-cross-white.svg'
import { capitalize } from "../../utils";

export default function BikeSpecsModal () { 
    
    const {modalState, toggleModalState, currentBike} = useContext(WindowContext)
    const isBikeSpecsModalVisible = modalState.bikeSpecsModal

    // Close modal when 'x' is clicked
    const handleCloseModal = () => {
        toggleModalState("bikeSpecsModal")
    }

    // Create JSX for specs table from currentBike.specs
    let toggleRowBgColour = true
    const specsJSX = Object.entries(currentBike.specs).map(spec => {
        toggleRowBgColour = !toggleRowBgColour
        const specHeader = spec[0]
        .split("_")           // Split the string by underscores
        .map(capitalize)      // Capitalize each word
        .join(" "); 
        return (
                <div className={`table-row ${toggleRowBgColour && "bg"}`} key={specHeader}>
                    <p className="row-header">{specHeader}:</p>
                    <p className="row-value">{spec[1]}</p>
                </div>
        )
    })
    
    return (
        <>
            <div className="modal-overlay" style={isBikeSpecsModalVisible ? {display : "block"} : {display : "none"}}></div>
            <div className="modal-wr" id="bike-specs-modal" style={isBikeSpecsModalVisible ? {display : "block"} : {display : "none"}}>
            <div className="modal-header">
                <h2 className="modal-title heading-xxl">Specs</h2>
                <img src={CloseIcon} alt="close icon" className="close-icon" onClick={handleCloseModal}/>
            </div>
                <div className="specs table">
                    {specsJSX}
                    
                </div>
                <p className="err-msg body-s text-center">We're currently using a free API service for our bike data, which may have some limitations. As a result, the latest models available are from 2022.</p>
            </div>
        </>
    )
}