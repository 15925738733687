import React from "react";
import { useState, useContext } from "react";
import CloseIcon from '../../assets/icon-cross-white.svg'
import emailjs from 'emailjs-com';
import {WindowContext} from '../../context/WindowContext'
import {UserContext} from '../../context/UserContext'
import LoadingSpinner from '../Modules/LoadingSpinner';
import FeedbackIcon from '../../assets/icon-feedback.svg'

export default function FeedbackModal() {

    const {modalState, toggleModalState} = useContext(WindowContext)
    const {userDetails} = useContext(UserContext)
    const feedbackVisable = modalState.feedbackModal
    const [feedback, setFeedback] = useState('');
    const [message, setMessage] = useState('');
    const [isloading, setIsLoading] = useState(false)

    // Hides Modal when 'x' is clicked
    const handleCloseModalIcon = () => {
        if (feedbackVisable) {
            toggleModalState("feedbackModal")
        }
        setFeedback('')
        setMessage('')
    }

    // Sends user feedback to officialmotodiary@gmail.com via EmailJS API
    const sendFeedback = (e) => {
        
        e.preventDefault();
        const serviceID = 'service_1z8a9jr';
        const templateID = 'template_fe4mjc9';
        const publicKey = 'asIJGphmuZytP8w1S';
    
        // Send the email
        setIsLoading(true)
        const email = userDetails.email
        emailjs
          .send(serviceID, templateID, { email, feedback }, publicKey)
          .then((response) => {
            setIsLoading(false)
            console.log('SUCCESS!', response.status, response.text);
            setMessage('Thank you for your feedback! We have received your message. We will be in touch soon if appropriate.');
            setFeedback('');
        })
        .catch((err) => {
            setIsLoading(false)
            console.error('FAILED...', err);
            setMessage('Sorry, there was an error with sending your feedback. Please try again later.');
            setFeedback('');
          });
      };
    
    return (
        <>
            <div className="modal-overlay" style={feedbackVisable ? {display : "block"} : {display : "none"}}></div>
            <div className="modal-wr" id="add-bike-modal" style={feedbackVisable ? {display : "block"} : {display : "none"}}>
                {!message ? 
                <>
                    {!isloading ? 
                        <>
                        <div className="modal-header">
                            <h2 className="modal-title heading-xxl">Feedback</h2>
                            <img src={CloseIcon} alt="close icon" className="close-icon" onClick={handleCloseModalIcon}/>
                        </div>
                        <div className="feedback-form">
                            <p className="body-s">
                                Let us know how you’re getting on with the app. Your feedback is important to us! We will personally respond to any relavent feedback.
                            </p>
                            {message && <p>{message}</p>}
                            <form onSubmit={sendFeedback}>
                                <div>
                                    <textarea
                                        name="feedback"
                                        placeholder={`- Have you found any bugs?\n- What do you like about the app?\n- What can we improve?\n- Feature ideas?`}
                                        className="feedback-textarea"
                                        onChange={(e) => setFeedback(e.target.value)}
                                        value={feedback}
                                        required
                                    />
                                </div>
                                <div>
                                <button type="submit" className="primary-btn">Send</button>
                                </div>
                            </form>
                            </div>
                        </>
                            :
                            <div style={{ width: '100%', display: 'flex' }}>
                                <LoadingSpinner />
                            </div>}
                        </>
                    :
                    <>
                        <div className="modal-header">
                            <h2 className="modal-title heading-l"></h2>
                            <img src={CloseIcon} alt="close icon" className="close-icon" onClick={handleCloseModalIcon}/>
                        </div>
                        <div className="feedback-message-wr">
                            <img src={FeedbackIcon} className="feedback-icon" alt="feedback-icon" /> 
                            <p className="text-center">{message}</p>
                        </div>  
                    </>}
                </div>
        </>
    )
}