import React, { useEffect, useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { auth } from "./firebase.js";
import { WindowProvider } from './context/WindowContext.jsx';
import { UserProvider } from './context/UserContext.jsx';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "./components/Auth/Login.jsx";
import Register from "./components/Auth/Register.jsx";
import VerifyEmailPrompt from "./components/Auth/VerifyEmailPrompt.jsx";
import PasswordReset from "./components/Auth/PasswordReset.jsx";
import Profile from "./components/Auth/Profile.jsx";

function App() {
  
  const [user, setUser] = useState();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      
      setUser(user);
    });
  });
  
  return (
    <WindowProvider>
      <UserProvider>
        <Router>
          <div className="App">
            
                <Routes>  
                  {/* Protected */}
                  <Route
                    path="/"
                    element={user ? <Navigate to="/profile/logbook" /> : <Login />}
                  />
                  <Route path="/profile/*" element={<Profile user={user} />} />

                  {/* Unprotected */}
                  <Route path="/login" element={<Login user={user} />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/verify-email" element={<VerifyEmailPrompt user={user} />} />
                  <Route path="/password-reset" element={<PasswordReset user={user} />} />
                </Routes>
                
                <ToastContainer />

          </div>
        </Router>
      </UserProvider>
    </WindowProvider>
  );
}

export default App;
